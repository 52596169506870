import React, { useEffect, useState } from 'react'

import { Content }  from '@components/Content'
import { Youtube }  from '@components/Youtube'
import { Seo }  from '@components/Seo'

const IndexPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {
      let { videos } = await import('@data/videos');

      setData(videos);
    })();
  }, []);

  return (
    <>
      <Content video={true}>
        <Seo title="Videos" description="Browse all videos by artists on Nutriot Recordings" />
        {
          data.length && data.map(item => {
            return (
              <section
                key={item.id}
                className="item"
              >
                <Youtube {...item} />
              </section>

            )
          })
        }
      </Content>
    </>
  );
};

export default IndexPage
