import React from 'react';

import './Youtube.scss';
import { Anchor } from '@components/Anchor';
import { Embed } from '@components/Embed';

const Youtube = props => {
  // const [release, setRelease] = useState({});

  // useEffect(() => {
  //   (async() => {
  //     let { releases } = await import('@data/releases');

  //     setRelease(releases.find(item => item.id === props.release));
  //   })();
  // }, []);

  const src = props.url
    ? props.url
    : `https://www.youtube-nocookie.com/embed/${props.id}`

  return (
    <>
      <Embed
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        aspectRatio="16:9"
        frameBorder="0"
        loading="lazy"
        scrolling="no"
        seamless={true}
        src={src}
        title={props.title ? props.title : false}
      />

      <h1>{props.title}</h1>
      <p>
        Directed by
        {' '}
        { props.director.url ?
          (
            <Anchor href={props.director.url} title={`Website of directory ${props.director.name}`}>{props.director.name}</Anchor>
          ) : props.director.name
        }
      </p>
    </>
  );
};

export default Youtube;
